import { Button, Col, Container, Div, Image, Row, StyleReset, Text, ThemeProvider } from "atomize"
import PageTransition from "gatsby-plugin-page-transitions"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import Footer from "../../components/footer"
import Header from "../../components/header"

import logoNoText from "../../images/squiseat-logo-ufficiale-senza-testo.png"
import SEO from "../../components/seo"
let config = require("../../settings/config")

const theme = config.theme
const url = config.url

const FAQEntry = props => {
    return (
        <Div pos="relative" p={{ b: { xs: "4rem", lg: "5rem" } }}>
            <Container>
                <Text textColor="accent" textSize={{ xs: "title", lg: "heading" }} textWeight="bold" textAlign="left">
                    {props.question}
                </Text>
                <Text textColor="accent_dark" textSize={{ xs: "title", lg: "heading" }} textAlign="left">
                    {props.answer}
                </Text>
            </Container>
        </Div>
    )
}

const FaqSupp = () => {
    return (
        <PageTransition>
            <ThemeProvider theme={theme}>
                <StyleReset />

                <Header />
                <SEO
                    title="Le Domande Frequenti fatte dai collaboratori"
                    description="Possiedi un locale? Hai domande sul nostro servizio antispreco? In questa pagina trovi le risposte alle Domande Frequenti che ci rivolgono i nostri partner."
                    pathname="faq-locali"
                />
                <main style={{ position: "relative", overflow: "hidden" }}>
                    <section>
                        <Div
                            bg="white"
                            pos="relative"
                            p={{
                                t: {
                                    xs: "0",
                                    md: "1rem",
                                    lg: "6rem",
                                    xl: "7rem",
                                },
                            }}
                            m={{ y: "1rem" }}
                        >
                            <Text tag="h1" d="none">
                                Le domande frequenti dei locali partner
                            </Text>
                            <Text tag="h2" d="none">
                                Come avviene il pagamento?
                            </Text>
                            <Text tag="h2" d="none">
                                Quando possono essere ritirati gli ordini?
                            </Text>
                            <Text
                                textColor="primary"
                                textAlign="center"
                                textSize={{
                                    xs: "display1",
                                    md: "display2",
                                    lg: "display3",
                                }}
                                p={{ y: "2rem" }}
                                textWeight="900"
                            >
                                Domande frequenti sui locali
                            </Text>

                            <FAQEntry
                                question="Come pagano i clienti?"
                                answer={
                                    <span>
                                        I clienti pagano sempre all’interno dell’app durante l’ordine, non dovrai
                                        neanche perdere tempo ad aprire la cassa!
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="In che momento avviene il ritiro degli ordini?"
                                answer={
                                    <span>
                                        Il momento lo scegli tu e ce lo comunichi non appena iniziamo a collaborare.
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="C’è qualche vincolo contrattuale?"
                                answer={
                                    <span>
                                        Non è presente nessun vincolo contrattuale.
                                        <br />
                                        Non dovrai caricare un minimo di prodotti ogni giorno, carica solo quelli che
                                        preferisci e nel momento che preferisci!
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="C’è un costo fisso?"
                                answer={
                                    <span>
                                        Nessun costo fisso, la permanenza sulla piattaforma e la pubblicità sono tutte
                                        gratuite.
                                        <br />
                                        Noi tratteniamo solamente una commissione del 25% sulle vendite effettive.
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="A quanto vengono venduti i miei prodotti?"
                                answer={
                                    <span>
                                        Attraverso Squiseat, ogni prodotto in eccedenza viene venduto al 50% del prezzo
                                        di listino{/*, mentre i prodotti in prenotazione a prezzo pieno*/}. In questo
                                        modo riduciamo insieme lo spreco!
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="In quale momento percepisco il mio guadagno?"
                                answer={
                                    <span>
                                        Ogni tre mesi vi versiamo l’importo corrispondente alle vendite, trattenendo la
                                        nostra commissione.
                                    </span>
                                }
                            />

                            <Div pos="relative" p={{ t: { xs: "6rem", lg: "7rem" } }}>
                                <Container>
                                    <Text textColor="primary" textSize="display3" textWeight="bold">
                                        Sei un cliente e hai dei dubbi?
                                    </Text>
                                    <Text textColor="primary" textSize="display1">
                                        Qui puoi trovare delle risposte
                                    </Text>
                                    <Link to={url.faqUser} title="Sono un utente | Squiseat">
                                        <Button
                                            w={{ xs: "12.5rem", md: "15rem" }}
                                            h={{ xs: "3rem", lg: "4rem" }}
                                            m={{ x: "auto", t: "3rem" }}
                                            bg="white"
                                            border="2px solid"
                                            borderColor="primary"
                                            rounded="circle"
                                        >
                                            <Text
                                                textColor="primary"
                                                textSize={{
                                                    xs: "subheader",
                                                    md: "title",
                                                }}
                                            >
                                                FAQ
                                            </Text>
                                        </Button>
                                    </Link>
                                </Container>
                            </Div>
                        </Div>
                    </section>
                </main>
                <Footer />
            </ThemeProvider>
        </PageTransition>
    )
}

export default FaqSupp
